import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import MelidataTrackerService from '@mshops-web-core/melidata-tracker';

import Button from '../../../../components/Button';
import PriceTable from '../../../../components/PriceTable';
import ContentChart from '../../../../wordings/chart/index';
import deviceSizes from '../../../../utils/deviceSizes';
import { BenefitsBaner } from '../../../../constants/melidata-events/activate-events';

const Chart = ({ i18n, deviceType, priceTable }) => {
  const [showAllRows, setShowAllRows] = useState(false);
  const { title, copy, shopButton, functionalityButton } = ContentChart(i18n);

  const buttonSize = deviceSizes[deviceType];

  const handleToggleRows = useCallback(() => {
    setShowAllRows(!showAllRows);
  }, [showAllRows]);

  const handleClickButton = useCallback(() => {
    MelidataTrackerService.notify(BenefitsBaner);
  }, []);

  const getButtonText = (showRows, textButton) =>
    `${showRows ? '-' : '+'} ${textButton}`;

  return (
    <section className="ui-ms-landing-chart">
      <div className="ui-ms-landing-chart__container">
        <h2 className="ui-ms-landing-chart__title">{title}</h2>
        <p className="ui-ms-landing-chart__copy">{copy}</p>
        <Button
          type="submit"
          text={shopButton}
          colorPalette="black"
          size={buttonSize}
          onClick={handleClickButton}
        />
        {priceTable?.rows && (
          <>
            <PriceTable items={priceTable?.rows} showAllRows={showAllRows} />
            <Button
              text={getButtonText(showAllRows, functionalityButton)}
              variant="secondary"
              colorPalette="black"
              onClick={handleToggleRows}
              hasIconRight={false}
              size={buttonSize}
              type="button"
            />
          </>
        )}
      </div>
    </section>
  );
};

Chart.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.string,
  priceTable: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

Chart.defaultProps = {
  deviceType: null,
  priceTable: null,
};

export default Chart;

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import Star from '../../assets/icons/Star';

const namespace = 'ui-ms-landing-stars';

const starClass = (index, rating) =>
  classNames({
    [`${namespace}--visible`]: index < rating,
    [`${namespace}--hidden`]: index >= rating,
  });

const StarCounter = ({ rating, color }) => {
  const renderStars = () =>
    Array(5)
      .fill()
      .map((_, i) => (
        <Star
          key={uuidv4()}
          color={color}
          size="22"
          className={starClass(i, rating)}
        />
      ));

  return (
    <div className="ui-ms-landing-testimonial-card__stars">{renderStars()}</div>
  );
};

StarCounter.propTypes = {
  rating: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default StarCounter;

import { functionalities } from '../commonWordings';

const ContentChart = (i18n) => ({
  /* i18n-next-line except: ["es-AR"] */
  title: i18n.gettext('Con Mercado Shops tener tu tienda online es gratis'),
  /* i18n-next-line except: ["es-AR"] */
  copy: i18n.gettext(
    'Con todas las funcionalidades, sin planes ni pagos extras.',
  ),
  /* i18n-next-line except: ["es-AR"] */
  shopButton: i18n.gettext('Crear tienda online'),
  /* i18n-next-line except: ["pt-BR", "es-AR"] */
  functionalityButton: functionalities(i18n),
});

export default ContentChart;

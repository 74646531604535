import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

import Card from '../Card';
import Button from '../Button';

const DefaultSlide = ({
  image,
  title,
  text,
  cta,
  onClick,
  type = 'submit',
  namespace = 'ui-ms-landing-default-slide',
  deviceType,
}) => {
  const ButtonSize = {
    desktop: 'lg',
    tablet: 'sm',
    mobile: 'sm',
  };

  return (
    <Card className={namespace}>
      <div className={`${namespace}__background-image`}>
        {deviceType === 'desktop' ? (
          <Image
            src={`${image}.png`}
            tabIndex={-1}
            alt=""
            className={`${namespace}__image`}
          />
        ) : (
          <Image
            src={`${image}_mobile.png`}
            tabIndex={-1}
            alt=""
            className={`${namespace}__image`}
          />
        )}
      </div>
      <div className={`${namespace}__container-bg`}>
        <div className={`${namespace}__text-content`}>
          <h2 className={`${namespace}__title`}>{title}</h2>
          <p className={`${namespace}__description`}>{text}</p>
          <Button
            text={cta}
            type={type}
            colorPalette="white"
            variant="tertiary"
            className={`${namespace}__button`}
            size={ButtonSize[deviceType]}
            onClick={onClick}
          />
        </div>
      </div>
    </Card>
  );
};

DefaultSlide.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  cta: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
  namespace: PropTypes.string,
};

export default DefaultSlide;

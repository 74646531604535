import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import FloatingCard from '../../../../components/FloatingCard';

const EcosystemDesktop = ({ cards, title, subtitle }) => {
  const namespace = 'ui-ms-landing-ecosystem';
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const containerElement = containerRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.6 },
    );

    if (containerElement) {
      observer.observe(containerElement);
    }

    return () => {
      observer.unobserve(containerElement);
    };
  }, []);

  const visibleCLassName = classNames(namespace, {
    [`${namespace}--visible`]: isVisible,
  });

  return (
    <section className={visibleCLassName} ref={containerRef}>
      <div className={`${namespace}__texts`}>
        <h2 className={`${namespace}__title`}>{title}</h2>
        <h5 className={`${namespace}__subtitle`}>{subtitle}</h5>
      </div>
      <div className={`${namespace}__card-container`}>
        {cards.map(({ image, border, background, opacity, id }) => (
          <FloatingCard
            image={image}
            borderRadius={border}
            bgColor={background}
            isFaded={opacity}
            size="lg"
            key={id}
          />
        ))}
      </div>
    </section>
  );
};

EcosystemDesktop.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      background: PropTypes.string.isRequired,
      border: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      opacity: PropTypes.bool,
      size: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default EcosystemDesktop;

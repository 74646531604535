import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { TabsContext } from './TabsContext';

const TabList = ({ children }) => {
  const { pillStyle } = useContext(TabsContext);
  const namespace = 'ui-ms-landing-tabs';

  return (
    <div className={`${namespace}__list`}>
      <div className={`${namespace}__pill`} style={pillStyle} />
      {children}
    </div>
  );
};

TabList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TabList;

import buildQueryString from './buildQueryString';
import { buildDeeplink, openInternalDeeplink } from './deeplink';

const onSubmitHandler = async ({
  event,
  siteId,
  url,
  deviceType,
  referer,
  queryParams,
  formAction,
  isWebview,
}) => {
  if (window?.MobileWebKit) {
    event.preventDefault();

    const deeplink = buildDeeplink({
      url: formAction,
      params: {
        authentication_mode: 'required',
      },
    });

    await openInternalDeeplink(deeplink);
  } else if (deviceType === 'mobile' && !isWebview) {
    event.preventDefault();

    window.location = `${url.mercadolibre[siteId]}${
      url.mercadoshops.hub
    }${buildQueryString(
      referer,
      queryParams,
      'goToLanding=true&mshopsLanding=activate',
    )}`;
  }
};

export default onSubmitHandler;

import React from 'react';
import PropTypes from 'prop-types';

const TabPanels = ({ children }) => (
  <div className="ui-ms-landing-tabs__panels">{children}</div>
);

TabPanels.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TabPanels;

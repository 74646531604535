import commonTodaySold from './commonTodaySold';

const CARD_IMAGES = {
  MCO: [
    { id: 0, image: 'home/MCO/card_hero_01.png', altImage: 'MercadoShops' },
    { id: 1, image: 'home/MCO/card_hero_02.png', altImage: 'MercadoShops' },
    { id: 2, image: 'home/MCO/card_hero_03.png', altImage: 'MercadoShops' },
  ],
  MLA: [
    { id: 0, image: 'home/MLA/card_hero_01.png', altImage: 'MercadoShops' },
    { id: 1, image: 'home/MLA/card_hero_02.png', altImage: 'MercadoShops' },
    { id: 2, image: 'home/MLA/card_hero_03.png', altImage: 'MercadoShops' },
  ],
  MLB: [
    { id: 0, image: 'home/MLB/card_hero_01.png', altImage: 'MercadoShops' },
    { id: 1, image: 'home/MLB/card_hero_02.png', altImage: 'MercadoShops' },
    { id: 2, image: 'home/MLB/card_hero_03.png', altImage: 'MercadoShops' },
  ],
  MLC: [
    { id: 0, image: 'home/MLC/card_hero_01.png', altImage: 'MercadoShops' },
    { id: 1, image: 'home/MLC/card_hero_02.png', altImage: 'MercadoShops' },
    { id: 2, image: 'home/MLC/card_hero_03.png', altImage: 'MercadoShops' },
  ],
  MLM: [
    { id: 0, image: 'home/MLM/card_hero_01.png', altImage: 'MercadoShops' },
    { id: 1, image: 'home/MLM/card_hero_02.png', altImage: 'MercadoShops' },
    { id: 2, image: 'home/MLM/card_hero_03.png', altImage: 'MercadoShops' },
  ],
};

const contentHero = (i18n, siteId) => ({
  title: commonTodaySold(i18n),
  /* i18n-next-line except: ["es-AR"] */
  subtitle: i18n.gettext('En todos lados, 24/7, en un clic, con Mercado Shops'),
  copy: i18n.gettext(
    'Súmate hoy a la plataforma de tiendas online de Mercado Libre, el e-commerce #1 de Latam.',
  ),
  button: i18n.gettext('Crea tu tienda gratis'),
  cardImages: CARD_IMAGES[siteId],
});

export default contentHero;

import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

import Step from './Step';

const StepperDesktop = ({ steps, activeItem, deviceType }) => {
  const namespace = 'ui-ms-landing-stepper';

  return (
    <div className={`${namespace}`}>
      <div className={`${namespace}__content-items`}>
        {steps.map(({ id, stepNumber, title, titleWithButton }) => (
          <Step
            key={id}
            stepNumber={stepNumber}
            active={id === activeItem}
            title={title}
            titleWithButton={titleWithButton}
            deviceType={deviceType}
          />
        ))}
      </div>
      <div className={`${namespace}__images`}>
        {steps.map(({ id, image, title }) => (
          <Image
            key={id}
            src={image}
            alt={title}
            className={`${namespace}__image ${
              activeItem === id
                ? `${namespace}__image--visible`
                : `${namespace}__image--hidden`
            }`}
          />
        ))}
      </div>
    </div>
  );
};

StepperDesktop.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepNumber: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      titleWithButton: PropTypes.shape({
        introText: PropTypes.string,
        buttonText: PropTypes.string,
        buttonLabelEvent: PropTypes.string,
        outroText: PropTypes.string,
      }),
    }),
  ).isRequired,
  activeItem: PropTypes.number.isRequired,
  deviceType: PropTypes.string.isRequired,
};

StepperDesktop.defaultProps = {
  steps: {
    titleWithButton: null,
  },
};

export default StepperDesktop;

import React from 'react';
import PropTypes from 'prop-types';

import Marquee from 'react-fast-marquee';

import ArrowMarquee from '../../../../assets/icons/ArrowMarquee';
import contentMarquee from '../../../../wordings/stepsMarquee';

const StepsMarquee = ({ i18n }) => {
  const nameclass = 'ui-ms-steps-marquee';
  const content = contentMarquee(i18n);

  return (
    <section className={nameclass}>
      <Marquee speed="100" autoFill>
        {content.map(({ title, id }) => (
          <div key={id} className={`${nameclass}__container`}>
            <p className={`${nameclass}__text`}>{title}</p>
            <ArrowMarquee className="ui-ms-landing-icon--arrow-marquee" />
          </div>
        ))}
      </Marquee>
    </section>
  );
};

StepsMarquee.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default StepsMarquee;

const ImageDesktop = {
  MLA: 'home/estilo_image@2x.png',
  MLB: 'home/MLB/estilo_image@2x.png',
  MLC: 'home/estilo_image@2x.png',
  MCO: 'home/estilo_image@2x.png',
  MLM: 'home/estilo_image@2x.png',
};

const MobileImageDesktop = {
  MLA: 'home/mobile_estilo_image@2x.png',
  MLB: 'home/MLB/mobile_estilo_image@2x.png',
  MLC: 'home/mobile_estilo_image@2x.png',
  MCO: 'home/mobile_estilo_image@2x.png',
  MLM: 'home/mobile_estilo_image@2x.png',
};

const contentHero = (i18n, siteId) => ({
  /* i18n-next-line except: ["es-AR"] */
  title: i18n.gettext('Tu marca, tu estilo'),
  text: i18n.gettext(
    'Elige entre distintos templates. Agrega, elimina o edita los componentes de tu Shop de manera fácil e intuitiva.',
  ),
  button: i18n.gettext('Empieza hoy'),
  image: ImageDesktop[siteId],
  imageMobile: MobileImageDesktop[siteId],
});

export default contentHero;

import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeft = ({ size = '24', color = 'black', className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    className={className}
  >
    <path d="M0.2,12.9c0.1,0.2,0.2,0.5,0.4,0.7l10,10c0.2,0.2,0.4,0.3,0.7,0.4c0.2,0,0.4,0.1,0.7,0.1c0.5,0,1-0.2,1.4-0.6 c0.2-0.2,0.3-0.4,0.4-0.7c0.2-0.2,0.2-0.4,0.2-0.7c0-0.5-0.2-1-0.6-1.4l-6.6-6.6H22c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4 s-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.4-0.6H6.8l6.6-6.6c0.2-0.2,0.3-0.4,0.4-0.7C14,2.6,14,2.4,14,2.1s-0.1-0.5-0.2-0.8 c-0.1-0.2-0.3-0.5-0.4-0.7c-0.2-0.2-0.4-0.3-0.7-0.4C12.5,0.1,12.2,0,11.9,0s-0.5,0.1-0.8,0.2c-0.2,0.1-0.5,0.3-0.7,0.4l-10,10 C0.2,10.8,0.1,11,0,11.3C0,11.8,0,12.4,0.2,12.9z" />
  </svg>
);

ArrowLeft.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default ArrowLeft;

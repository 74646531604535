import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';
import classNames from 'classnames';

import Card from '../Card';

const FloatingCard = ({
  image,
  borderRadius,
  bgColor,
  isFaded,
  size,
  className,
}) => {
  const ClassCard = classNames({
    'ui-ms-landing-floating-card': true,
    'ui-ms-landing-floating-card--opacity': isFaded,
    [`ui-ms-landing-floating-card--${size}`]: size,
    [className]: className,
  });

  return (
    <Card bgColor={bgColor} borderRadius={borderRadius}>
      <div className={ClassCard}>
        <Image
          src={image}
          className="ui-ms-landing-floating-card__image"
          alt=""
        />
      </div>
    </Card>
  );
};

FloatingCard.propTypes = {
  image: PropTypes.string.isRequired,
  isFaded: PropTypes.bool,
  size: PropTypes.string.isRequired,
  borderRadius: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default FloatingCard;

import PATHS from './paths';
import { getCommonDataEvents } from './common-data-events';

const MainBanner = {
  path: PATHS.ACTIVATE,
  data: getCommonDataEvents('main_banner'),
};

const CardsBanner = {
  path: PATHS.ACTIVATE,
  data: getCommonDataEvents('more_info_banner'),
};

const MeliSeller = {
  path: PATHS.ACTIVATE,
  data: getCommonDataEvents('meli_seller'),
};

const NotMeliSeller = {
  path: PATHS.ACTIVATE,
  data: getCommonDataEvents('not_meli_seller'),
};

const DesignBanner = {
  path: PATHS.ACTIVATE,
  data: getCommonDataEvents('design_banner'),
};

const BenefitsBaner = {
  path: PATHS.ACTIVATE,
  data: getCommonDataEvents('benefits_baner'),
};

const NoLimitsBanner = {
  path: PATHS.ACTIVATE,
  data: getCommonDataEvents('no_limits_banner'),
};

export {
  MainBanner,
  CardsBanner,
  MeliSeller,
  NotMeliSeller,
  DesignBanner,
  BenefitsBaner,
  NoLimitsBanner,
};

import React from 'react';
import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';

import contentTools from '../../../../wordings/tools';
import Carousel from '../../../../components/Carousel';
import InfoCard from '../../../../components/InfoCard';

const Tools = ({ i18n, siteId }) => {
  const { title, description, cards } = contentTools(i18n, siteId);

  const carouselSettings = {
    dots: false,
    arrows: false,
    draggable: false,
    fade: false,
    safeGapItems: false,
    infinite: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          fade: false,
          variableWidth: true,
          dots: true,
          infinite: false,
        },
      },
    ],
  };
  const namespace = 'ui-ms-landing-tools';

  return (
    <section className={namespace}>
      <div className={`${namespace}__container`}>
        <div className={`${namespace}__text-container`}>
          <h2 className={`${namespace}__title`}>{title}</h2>
          <p className={`${namespace}__description`}>{description}</p>
        </div>
        <div className={`${namespace}__carousel-container`}>
          <Carousel {...carouselSettings}>
            {cards.map(({ text, animation }) => (
              <InfoCard key={uuidv4()} title={text} animation={animation} />
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

Tools.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default Tools;

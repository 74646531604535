const metaDescriptions = {
  MLA: 'Creá tu tienda online con Mercado Shops. Contá con todas las soluciones de Mercado Libre en tu tienda virtual. Mercado Pago incluido en tu tarifa. La única plataforma de ecommerce con Mercado Envíos Full.',
  MLB: 'Crie sua loja online com o Mercado Shops. Possui todas as soluções do Mercado Libre em sua loja virtual. Mercado Pago incluído na sua tarifa. A única plataforma de comércio eletrônico com Full Market Shipping.',
  default:
    'Crea tu tienda online con Mercado Shops. Cuenta con todas las soluciones de Mercado Libre en tu tienda virtual. Mercado Pago incluido en tu tarifa. La única plataforma de ecommerce con Mercado Envíos Full.',
};

const metaKeywords = {
  MLB: 'Comércio eletrônico, loja online, Mercado Shops, Mercado Libre, Mercado Pago, Mercado Shipping, vender online, vender no Instagram, vender no Facebook, vender no Mercado Libre, Loja Mercado Libre:',
  default:
    'Ecommerce, tienda online, Mercado Shops, Mercado Libre, Mercado Pago, Mercado Envíos, vender por Internet, Vender por Instagram, vender por Facebook, vender por Mercado Libre, Tienda Mercado Libre:',
};

const metaDescriptionsPro = {
  MLA: 'Mercado Shops Pro, la mejor opción para lograr lo extraordinario. Servicio exclusivo para marcas en expansión. Apoyo integral. Todas las soluciones de Mercado Libre. Mercado Pago. Mercado Envíos Full.',
  MLB: 'Mercado Shops, a melhor opção para alcançar o extraordinário. Serviço exclusivo para marcas em expansão. Apoio integral. Todas as soluções do Mercado Livre. Mercado Pago. Mercado Envios Full.',
  default:
    'Mercado Shops Pro, la mejor opción para lograr lo extraordinario. Servicio exclusivo para marcas en expansión. Apoyo integral. Todas las soluciones de Mercado Libre. Mercado Pago. Mercado Envíos Full.',
};

const metaKeywordsPro = {
  MLA: 'Asesoramiento dedicado, Apoyo Integral, Programas de aceleración, Plan de negocios, Ecommerce, Tienda online, Mercado Shops, Mercado Libre, Mercado Pago, Expertos Certificados, Informes personalizados, Migración asistida, Vender por Internet.',
  MLB: 'Assessoramento exclusivo, Apoio integral, Programas de aceleração, Plano de negócios, E-commerce, Loja virtual, Loja online, Mercado Shops, Mercado Livre, Mercado Pago, Especialistas Certificados, Relatórios personalizados, Migração assistida, Vender pela Internet.',
  default:
    'Asesoramiento dedicado, Apoyo Integral, Programas de aceleración, Plan de negocios, Ecommerce, Tienda online, Mercado Shops, Mercado Libre, Mercado Pago, Expertos Certificados, Informes personalizados, Migración asistida, Vender por Internet,',
};

const pageTitlePro = {
  MLA: 'Mercado Shops Pro - Elevá tu negocio',
  MLB: 'Mercado Shops Pro – Eleve o seu negócio',
  default: 'Mercado Shops Pro - Eleva tu negocio',
};

export const getMetaDescription = (siteId) => {
  if (metaDescriptions[siteId]) {
    return metaDescriptions[siteId];
  }

  return metaDescriptions.default;
};

export const getMetaDescriptionPro = (siteId) => {
  if (metaDescriptionsPro[siteId]) {
    return metaDescriptionsPro[siteId];
  }

  return metaDescriptionsPro.default;
};

export const getMetaKeywords = (siteId) => {
  if (metaKeywords[siteId]) {
    return metaKeywords[siteId];
  }

  return metaKeywords.default;
};

export const getMetaKeywordsPro = (siteId) => {
  if (metaKeywordsPro[siteId]) {
    return metaKeywordsPro[siteId];
  }

  return metaKeywordsPro.default;
};

export const getTitlePro = (siteId) => {
  if (pageTitlePro[siteId]) {
    return pageTitlePro[siteId];
  }

  return pageTitlePro.default;
};

export default getMetaDescription;

import React from 'react';
import PropTypes from 'prop-types';

import FAQAccordion from '../../../../components/FAQAccordion';
import contentFaq from '../../../../wordings/faq';

const Faq = ({ i18n }) => {
  const namespace = 'faq';
  const faqAccordion = contentFaq(i18n);
  const { title } = contentFaq(i18n);

  return (
    <section className={namespace}>
      <div className={`${namespace}__container`}>
        <h2 className={`${namespace}__title`}>{title}</h2>
        <FAQAccordion items={faqAccordion.items} />
      </div>
    </section>
  );
};

Faq.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default Faq;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import MelidataTrackerService from '@mshops-web-core/melidata-tracker';

import Button from '../../../../components/Button';
import contentHero from '../../../../wordings/hero';
import { MainBanner } from '../../../../constants/melidata-events/activate-events';

const HeroMobile = ({ i18n }) => {
  const Data = contentHero(i18n);
  const { title, subtitle, copy, button } = Data;

  const handleClickButtonSubmit = useCallback(() => {
    MelidataTrackerService.notify(MainBanner);
  }, []);

  return (
    <section className="ui-ms-landing-hero">
      <div className="ui-ms-landing-hero__container">
        <h1 className="ui-ms-landing-hero__title">{title}</h1>
        <p className="ui-ms-landing-hero__subtitle">{subtitle}</p>
        <p className="ui-ms-landing-hero__copy">{copy}</p>
        <Button
          type="submit"
          text={button}
          colorPalette="yellow"
          size="sm"
          hasIconRight
          onClick={handleClickButtonSubmit}
        />
      </div>
    </section>
  );
};

HeroMobile.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
};

export default HeroMobile;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useI18n } from 'nordic/i18n';
import config from 'nordic/config';
import { Head } from 'nordic/head';

import GTM from '../components/Pixels/Gtm';
import { getSite } from '../../services/home';
import Hero from './home/components/Hero';
import Chart from './home/components/Chart';
import Estilo from './home/components/Estilo';
import ShopWithoutLimits from './home/components/ShopWithoutLimits';
import Ecosystem from './home/components/Ecosystem';
import Sales from './home/components/Sales';
import Tools from './home/components/Tools';
import Faq from './home/components/Faq';
import StepsMarquee from './home/components/StepsMarquee';
import CreateYourShop from './home/components/CreateYourShop';
import Cases from './home/components/Cases';
import { getMetaDescription, getMetaKeywords } from '../utils/metaResolver';
import { EVENTS_TYPES, ACTION_SOURCE } from '../constants/commons';
import { SendFacebookTrack } from '../utils/facebookTracks';
import LogErrorFacebookTrack from '../utils/loggerFacebookTracks';
import onSubmitHandler from '../utils/onSubmitHandler';
import handleMobileRedirection from '../utils/handleMobileRedirection';
import getFormAction from '../utils/getFormAction';

const Home = (props) => {
  const { i18n } = useI18n();

  const {
    deviceType = null,
    siteId,
    queryParams,
    referer,
    pathName,
    isWebview,
    url,
    pages,
    gtmId,
    outdated,
    priceTable,
  } = props;

  const formAction = getFormAction({
    url,
    siteId,
    activationPage: pages.activate,
    referer,
    queryParams,
  });

  useEffect(() => {
    handleMobileRedirection({
      deviceType,
      isWebview,
      pathName,
      url,
      siteId,
      referer,
      queryParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (event) => {
    await onSubmitHandler({
      event,
      siteId,
      url,
      deviceType,
      referer,
      queryParams,
      formAction,
      isWebview,
    });
  };

  return (
    <>
      <Head>
        <meta name="description" content={getMetaDescription(siteId)} />
        <meta
          name="og:image"
          content="https://http2.mlstatic.com/static/org-img/homesnw/mercado-libre.png?v=2"
        />
        <meta name="keywords" content={getMetaKeywords(siteId)} />
      </Head>
      <GTM lowEnd={outdated} id={gtmId} />
      <form action={formAction} method="get" onSubmit={onSubmit}>
        <Hero i18n={i18n} deviceType={deviceType} siteId={siteId} />
        <Ecosystem i18n={i18n} deviceType={deviceType} siteId={siteId} />
        <Sales i18n={i18n} siteId={siteId} deviceType={deviceType} />
        <CreateYourShop i18n={i18n} siteId={siteId} deviceType={deviceType} />
        <StepsMarquee i18n={i18n} />
        <Tools i18n={i18n} siteId={siteId} deviceType={deviceType} />
        <Estilo i18n={i18n} deviceType={deviceType} siteId={siteId} />
        <Cases i18n={i18n} deviceType={deviceType} siteId={siteId} />
        <Chart priceTable={priceTable} i18n={i18n} deviceType={deviceType} />
        <ShopWithoutLimits i18n={i18n} deviceType={deviceType} />
        <Faq i18n={i18n} />
      </form>
    </>
  );
};

export const hydrate = 'hydrateRoot';

export const getServerSideProps = async (req, res) => {
  const { i18n, cookies, device, browser, platform, query } = req;
  const site = await getSite(platform.siteId, req);
  const { gtmId, isWebview, priceTable } = res.locals || {};

  const userAgent = req.get('User-Agent');
  const ipAddress = req.ip || req.socket.remoteAddress;
  const { hostname, originalUrl } = req;
  const urlSource = `${hostname}${originalUrl}`;
  const shopId = res.locals?.shopId || req.auth?.user?.rootId || null;
  const { _fbp: fbp } = cookies;
  const { fbclid } = query;

  try {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await SendFacebookTrack({
      eventType: EVENTS_TYPES.PAGE_VIEW,
      source: ACTION_SOURCE.WEBSITE,
      userAgent,
      urlSource,
      siteId: req.platform.siteId,
      shopId,
      fbp,
      fbc: fbclid,
      ipAddress,
      userData: {},
      req,
    });
  } catch (e) {
    LogErrorFacebookTrack({
      idLog: 'error in getServerSide Function from home page',
      error: e,
      siteId: platform.siteId,
      shopId,
    });
  }

  return {
    props: {
      site,
      outdated: browser.outdated,
      deviceType: device.type,
      company: config.get('companyName', platform.id, platform.siteId),
      siteId: platform.siteId,
      username: req.auth?.user?.nickname,
      queryParams: query,
      referer: req.header('Referer') || '',
      pathName: req.originalUrl,
      url: config.url,
      pages: config.pages,
      gtmId,
      isWebview,
      priceTable,
    },
    settings: {
      /* i18n-next-line except: ["es-AR"] */
      title: i18n.gettext('Mercado Shops - Tu tienda online'),
    },
  };
};

export const setPageSettings = ({ settings }) => ({
  title: settings.title,
  className: 'home',
  melidata: {
    path: '/home/landing',
    event_data: {},
  },
  navigation: {
    type: 'landing-rounded',
  },
  layout: {
    staticMarkup: false,
  },
});

Home.propTypes = {
  site: PropTypes.shape({
    name: PropTypes.string.isRequired,
    default_currency_id: PropTypes.string.isRequired,
  }).isRequired,
  deviceType: PropTypes.string,
  siteId: PropTypes.string.isRequired,
  queryParams: PropTypes.shape({}),
  referer: PropTypes.string.isRequired,
  pathName: PropTypes.string.isRequired,
  isWebview: PropTypes.bool.isRequired,
  url: PropTypes.shape({
    mercadolibre: PropTypes.shape({
      MCO: PropTypes.string.isRequired,
      MLA: PropTypes.string.isRequired,
      MLB: PropTypes.string.isRequired,
      MLC: PropTypes.string.isRequired,
      MLM: PropTypes.string.isRequired,
    }).isRequired,
    mercadoshops: PropTypes.shape({
      hub: PropTypes.string.isRequired,
    }).isRequired,
    landing: PropTypes.shape({
      MCO: PropTypes.string.isRequired,
      MLA: PropTypes.string.isRequired,
      MLB: PropTypes.string.isRequired,
      MLC: PropTypes.string.isRequired,
      MLM: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pages: PropTypes.shape({
    activate: PropTypes.string.isRequired,
  }).isRequired,
  gtmId: PropTypes.string.isRequired,
  outdated: PropTypes.bool.isRequired,
  priceTable: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default Home;

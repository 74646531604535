import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import FAQAccordionItem from './FAQAccordionItem';

const FAQAccordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = useCallback(
    (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    },
    [activeIndex],
  );

  return (
    <div className="ui-ms-landing-faq-accordion">
      {items.map(({ id, number, title, description }, index) => (
        <FAQAccordionItem
          key={id}
          number={number}
          title={title}
          description={description}
          isActive={activeIndex === index}
          index={index}
          onClick={handleClick}
        />
      ))}
    </div>
  );
};

FAQAccordion.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      number: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default FAQAccordion;

import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

const IconButton = ({
  variant,
  icon,
  url,
  target,
  colorPalette,
  className,
  onClick,
  size,
}) => {
  const iconBtnClassName = classnames(
    'ui-ms-landing-icon-button',
    `ui-ms-landing-icon-button--${size}`,
    `ui-ms-landing-icon-button--${variant}`,
    `ui-ms-landing-icon-button--${variant}-${colorPalette}`,
    className,
  );

  return (
    <a
      href={url}
      target={target}
      className={iconBtnClassName}
      onClick={onClick}
    >
      {React.cloneElement(icon, {
        className: 'ui-ms-landing-icon-button__icon',
      })}
    </a>
  );
};

IconButton.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  url: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xlg', 'lg', 'sm']),
  target: PropTypes.oneOf(['_blank', '_self']),
  colorPalette: PropTypes.oneOf([
    'yellow',
    'pink',
    'black',
    'white',
    'white-yellow',
  ]),
};

IconButton.defaultProps = {
  variant: 'secondary',
  target: '_self',
  url: '',
  colorPalette: 'black',
  size: 'lg',
  className: 'ui-ms-landing-icon-button',
};

export default IconButton;

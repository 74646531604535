import React from 'react';
import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';

const PillFade = ({ items }) => {
  const namespace = 'ui-ms-landing-pill-fade-animation';

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div className={namespace}>
      {items.map(({ title }) => (
        <div key={uuidv4()} className={`${namespace}__cont-text`}>
          {title}
        </div>
      ))}
    </div>
  );
};

PillFade.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default PillFade;

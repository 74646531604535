import { buildDeeplink } from './deeplink';
import buildQueryString from './buildQueryString';
import getStringQueryParams from './getStringQueryParams';

const handleMobileRedirection = ({
  deviceType,
  isWebview,
  pathName,
  url,
  siteId,
  referer,
  queryParams,
}) => {
  const stringQueryParams = getStringQueryParams(queryParams);

  if (deviceType === 'mobile' && !isWebview) {
    const urlWithParams = pathName?.includes('landing')
      ? `${url.landing[siteId]}${pathName}${buildQueryString(
          referer,
          stringQueryParams,
        )}`
      : `${url.mercadolibre[siteId]}${url.mercadoshops.hub}${buildQueryString(
          referer,
          stringQueryParams,
        )}`;

    const landingDeeplink = buildDeeplink({
      url: urlWithParams,
      params: {
        refresh_mode: 'pull',
      },
    });

    window.location = landingDeeplink;
  }
};

export default handleMobileRedirection;

import buildQueryString from './buildQueryString';
import getStringQueryParams from './getStringQueryParams';

const getFormAction = ({
  url,
  siteId,
  activationPage,
  referer,
  queryParams,
}) => {
  const stringQueryParams = getStringQueryParams(queryParams);

  return `${url.mercadolibre[siteId]}${activationPage}${buildQueryString(
    referer,
    stringQueryParams,
  )}`;
};

export default getFormAction;

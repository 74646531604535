import React from 'react';
import PropTypes from 'prop-types';

const Star = ({ size = '24', color = 'black' }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill={color}>
    <path d="M11.9925 18.197L5.01466 21.7971L6.34731 14.172L0.702148 8.77183L8.50356 7.65934L11.9925 0.721802L15.4814 7.65934L23.2828 8.77183L17.6376 14.172L18.9703 21.7971L11.9925 18.197Z" />
  </svg>
);

Star.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

export default Star;

import React from 'react';
import PropTypes from 'prop-types';

const IconNumber = ({ number, colorPalette, size, variant }) => {
  const namespace = 'ui-ms-landing-icon-number';

  return (
    <div
      className={`${namespace} ${namespace}--${variant}--${colorPalette} ${namespace}--${size}`}
    >
      {number}
    </div>
  );
};

IconNumber.propTypes = {
  colorPalette: PropTypes.oneOf(['yellow', 'pink', 'black', 'white']),
  number: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['filled', 'outlined']),
};

IconNumber.defaultProps = {
  colorPalette: 'black',
  size: 'lg',
  variant: 'outlined',
};

export default IconNumber;

import React from 'react';
import PropTypes from 'prop-types';

import dataCreateYourShop from '../../../../wordings/createYourShop';
import CreateYourShopDesktop from './CreateYourShopDesktop';
import CreateYourShopMobile from './CreateYourShopMobile';

const CreateYourShop = ({ i18n, siteId, deviceType }) => {
  const data = dataCreateYourShop(i18n, siteId);

  const { title, sellingTab, notSellingTab, sellingStep, notSellingStep } =
    data;

  const CreateYourShopByDevice = {
    mobile: CreateYourShopMobile,
    tablet: CreateYourShopDesktop,
    desktop: CreateYourShopDesktop,
  };

  const CreateYourShopComponent =
    CreateYourShopByDevice[deviceType] || CreateYourShopDesktop;

  return (
    <CreateYourShopComponent
      title={title}
      sellingTab={sellingTab}
      notSellingTab={notSellingTab}
      sellingStep={sellingStep}
      notSellingStep={notSellingStep}
      deviceType={deviceType}
    />
  );
};

CreateYourShop.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.string.isRequired,
};

export default CreateYourShop;

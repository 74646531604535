import React from 'react';
import PropTypes from 'prop-types';

import CasesDesktop from './CasesDesktop';
import CasesMobile from './CasesMobile';
import dataCases from '../../../../wordings/cases';

const Cases = ({ i18n, siteId, deviceType }) => {
  const data = dataCases(i18n, siteId);
  const { title, cards } = data;
  const casesByDevice = {
    mobile: CasesMobile,
    tablet: CasesDesktop,
    desktop: CasesDesktop,
  };

  const CasesComponent = casesByDevice[deviceType] || CasesDesktop;

  return (
    <CasesComponent i18n={i18n} siteId={siteId} cards={cards} title={title} />
  );
};

Cases.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.string.isRequired,
};

export default Cases;

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import ArrowRight from '../../assets/icons/ArrowRight';

const Button = ({
  colorPalette,
  type,
  onClick,
  size,
  hasIconRight,
  variant,
  text,
  className,
}) => {
  const btnClassName = classNames({
    'ui-ms-landing-button': true,
    [`ui-ms-landing-button__${variant}`]: true,
    [`ui-ms-landing-button__${variant}--${colorPalette}`]: true,
    [`ui-ms-landing-button__${variant}-size--${size}`]: true,
    [`ui-ms-landing-button__size--${size}`]: true,
    [className]: className,
  });

  return (
    // eslint-disable-next-line react/button-has-type
    <button className={btnClassName} type={type} onClick={onClick}>
      <span className="ui-ms-landing-button__text">{text}</span>
      {hasIconRight && (
        <span className="ui-ms-landing-button__icon">
          <ArrowRight className="ui-ms-landing-button__icon--right" />
        </span>
      )}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  text: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'lg']),
  colorPalette: PropTypes.oneOf(['yellow', 'pink', 'black', 'white']),
  hasIconRight: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  colorPalette: 'yellow',
  type: 'submit',
  onClick: null,
  size: 'lg',
  hasIconRight: true,
  variant: 'primary',
};

export default Button;

import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';
import classNames from 'classnames';

const Avatar = ({
  hasTopLine = false,
  image = '',
  altText = '',
  text = '',
  colorText = 'black',
  children = null,
  bgColor = 'transparent',
}) => {
  const namespace = 'ui-ms-avatar';

  const textClass = classNames('ui-ms-avatar__text', {
    [`ui-ms-avatar__text--${colorText}-text`]: colorText,
  });

  const imageContainer = classNames(`${namespace}__image-container`, {
    [`${namespace}__image-container--${bgColor}-bg`]: bgColor,
  });

  const lineClass = classNames('ui-ms-avatar__line', {
    [`ui-ms-avatar__line--${colorText}-line`]: colorText,
  });

  const renderAvatarContent = image ? (
    <Image src={image} alt={altText} className={`${namespace}__image`} />
  ) : (
    children
  );

  return (
    <>
      {hasTopLine && <div data-testid="top-line" className={lineClass} />}
      <div className={namespace}>
        {(image || children) && (
          <div data-testid="avatar-image" className={imageContainer}>
            {renderAvatarContent}
          </div>
        )}
        {text && <span className={textClass}>{text}</span>}
      </div>
    </>
  );
};

Avatar.propTypes = {
  hasTopLine: PropTypes.bool,
  image: PropTypes.string,
  children: PropTypes.node,
  text: PropTypes.string,
  altText: PropTypes.string,
  colorText: PropTypes.oneOf(['black', 'white']),
  bgColor: PropTypes.oneOf([
    'yellow',
    'pink',
    'black',
    'blue',
    'white',
    'transparent',
  ]),
};

export default Avatar;

const CARD_DATA = {
  MLA: [
    {
      id: 0,
      rating: 5,
      testimony:
        '"Fue muy simple desarrollar una estrategia exitosa en ventas porque nos apoyamos en el ecosistema de Mercado Libre".',
      image: 'home/MLA/report.png',
      brand: 'Tienda Report',
      color: 'white',
      bgColor: 'blue',
    },
    {
      id: 1,
      rating: 5,
      testimony:
        '“Decidimos estar a la vanguardia en la forma de vender, por eso utilizamos Mercado Shops”.',
      image: 'home/MLA/ferraro.png',
      brand: 'Ferraro',
      color: 'black',
      bgColor: 'yellow',
    },
    {
      id: 2,
      rating: 5,
      testimony:
        '"Con Mercado Shops, pudimos utilizar las herramientas que nos estaban haciendo falta para alcanzar los objetivos de venta".',
      image: 'home/MLA/apacuero.png',
      brand: 'Apacuero',
      color: 'white',
      bgColor: 'black',
    },
    {
      id: 3,
      rating: 5,
      testimony:
        '"El ecosistema de Mercado Libre nos ayuda a potenciar la venta directa de nuestros productos. Mercado Shops lo hace realmente simple".',
      image: 'home/MLA/chilly.png',
      brand: 'Chilly',
      color: 'white',
      bgColor: 'pink',
    },
    {
      id: 4,
      rating: 5,
      testimony:
        '“Es muy fácil empezar a vender online, siempre y cuando tengas el respaldo de un ecosistema como Mercado Libre”.',
      image: 'home/MLA/htvs.png',
      brand: 'HTVS',
      color: 'white',
      bgColor: 'blue',
    },
  ],
  MLB: [
    {
      id: 0,
      rating: 5,
      testimony:
        '“Foi uma grata surpresa vermos que tudo o que tínhamos em mente se tornou possível para nossa loja virtual em tão pouco tempo.”',
      image: 'home/MLB/mae_terra.png',
      brand: 'Mãe Terra',
      color: 'white',
      bgColor: 'blue',
    },
    {
      id: 1,
      rating: 5,
      testimony:
        '“Crescemos +2500%YoY. A integração nativa aos sistemas e serviços como Fullfilment e Mercado Pago expandiu nossa operação de forma eficiente.”',
      image: 'home/MLB/hub.png',
      brand: 'Hub Smart Home',
      color: 'black',
      bgColor: 'yellow',
    },
    {
      id: 2,
      rating: 5,
      testimony:
        '“Interface intuitiva e fácil de usar. A integração com o Mercado Livre facilita a gestão do e-commerce e otimiza tempo e custos operacionais.”',
      image: 'home/MLB/nova_era.png',
      brand: 'Cirurgica nova era',
      color: 'white',
      bgColor: 'black',
    },
    {
      id: 3,
      rating: 5,
      testimony:
        '“O Mercado Shops foi um passo importante na nossa atuação. A plataforma oferece muitas soluções de design e organização que são fundamentais.”',
      image: 'home/MLB/brinkanto.png',
      brand: 'Brinkanto',
      color: 'white',
      bgColor: 'pink',
    },
    {
      id: 4,
      rating: 5,
      testimony:
        '“O Mercado Shops é estável, 100% confiável e seguro. Utilizamos a plataforma há mais de 4 anos e nunca tivemos invasão ou queda do site.”',
      image: 'home/MLB/ismafer.png',
      brand: 'Ismafer',
      color: 'white',
      bgColor: 'blue',
    },
  ],
  MLC: [
    {
      id: 0,
      rating: 5,
      testimony:
        '“Tener el ecommerce integrado en el ecosistema Mercado Libre nos permitió mejor control de inventario, estrategia de precios y vender mayor volumen a mejor rentabilidad”.',
      image: 'home/MLC/mabe.png',
      brand: 'Mabe Electrodomesticos',
      color: 'white',
      bgColor: 'blue',
    },
    {
      id: 1,
      rating: 5,
      testimony:
        '“El ecosistema robusto de Mercado Shops nos permite escalar nuestro negocio y productos al siguiente nivel”.',
      image: 'home/MLC/repuestos_del_sol.png',
      brand: 'Repuestos del Sol',
      color: 'black',
      bgColor: 'yellow',
    },
    {
      id: 2,
      rating: 5,
      testimony:
        '“Simplemente la herramienta perfecta para complementar nuestra estrategia de branding dando mayor escalabilidad a nuestro negocio”.',
      image: 'home/MLC/3_angeli_home.png',
      brand: '3angeli Home & Deco',
      color: 'white',
      bgColor: 'black',
    },
  ],
  MLM: [
    {
      id: 0,
      rating: 5,
      testimony:
        '“Utilizar el ecosistema de Mercado Libre en nuestra tienda en línea nos ayudó a crecer exponencialmente.”',
      image: 'home/MLM/montepio_luz_savinon.png',
      brand: 'Montepío Luz Saviñón',
      color: 'white',
      bgColor: 'blue',
    },
    {
      id: 1,
      rating: 5,
      testimony:
        '“Fue muy fácil crear una página profesional. El centro de distribución de Full nos ayuda a vender más y entregar nuestros productos en el mismo día.”',
      image: 'home/MLM/comando.png',
      brand: 'Comando',
      color: 'black',
      bgColor: 'yellow',
    },
    {
      id: 2,
      rating: 5,
      testimony:
        '“Que el catálogo de productos esté vinculado a Mercado Libre nos ahorró mucho trabajo. La tienda virtual es práctica y rápida de configurar.”',
      image: 'home/MLM/sohrelia.png',
      brand: 'Sohrelia',
      color: 'white',
      bgColor: 'black',
    },
    {
      id: 3,
      rating: 5,
      testimony:
        '“Mercado Shops es mucho más que una plataforma de comercio electrónico; es el motor que impulsa nuestro éxito en el mundo digital."',
      image: 'home/MLM/meguiars.png',
      brand: 'Meguiars',
      color: 'white',
      bgColor: 'pink',
    },
    {
      id: 4,
      rating: 5,
      testimony:
        '“Es fácil de usar, los pagos son 100 % seguros y las comisiones son bajas. Además tenemos mucha información clara para la toma de decisiones.”',
      image: 'home/MLM/dimobeler.png',
      brand: 'Dimobeler',
      color: 'white',
      bgColor: 'blue',
    },
  ],
  MCO: [
    {
      id: 0,
      rating: 5,
      testimony:
        '“Cumplió con todo lo que buscábamos: completo, práctico e intuitivo. Mercado Shops es funcionalidad y excelencia.”',
      image: 'home/MCO/megatienda.png',
      brand: 'Megatienda',
      color: 'white',
      bgColor: 'blue',
    },
    {
      id: 1,
      rating: 5,
      testimony:
        '“Es una plataforma robusta para crear tu propia página web. La integración con la logística de envíos y pasarela de pagos la hace muy práctica.”',
      image: 'home/MCO/croydon.png',
      brand: 'Croydon',
      color: 'black',
      bgColor: 'yellow',
    },
    {
      id: 2,
      rating: 5,
      testimony:
        '“Una excelente herramienta para empezar las ventas en línea o complementar las ventas de Mercado Libre. Es muy fácil de usar.”',
      image: 'home/MCO/audiotechnica.png',
      brand: 'AudioTechnica',
      color: 'white',
      bgColor: 'black',
    },
  ],
};

const dataCases = (i18n, siteId) => ({
  title: i18n.gettext('Súmate a una comunidad en constante evolución'),
  cards: CARD_DATA[siteId],
});

export default dataCases;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

import { TabsContext } from './TabsContext';

const TabPanel = ({ children, index }) => {
  const { visibleContent, fade } = useContext(TabsContext);

  const namespace = 'ui-ms-landing-tabs';

  const contentStyles = classnames(`${namespace}__panel`, {
    [`${namespace}__panel--fade`]: fade,
    [`${namespace}__panel--visible`]: index === visibleContent,
    [`${namespace}__panel--hidden`]: index !== visibleContent,
  });

  return <div className={contentStyles}>{children}</div>;
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
};

export default TabPanel;

import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import IconNumber from '../IconNumber';
import ArrowDownBig from '../../assets/icons/ArrowDownBig';

const FAQAccordionItem = ({
  number,
  title,
  description,
  isActive,
  index,
  onClick,
}) => {
  const [maxHeight, setMaxHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    if (isActive && contentRef.current) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setMaxHeight('0px');
    }
  }, [isActive]);

  const handleClick = () => {
    onClick(index);
  };

  const namespace = 'ui-ms-landing-faq-accordion-item';

  const itemClass = classNames({
    [`${namespace}`]: true,
    [`${namespace}--open`]: isActive,
  });

  return (
    <div
      className={itemClass}
      onClick={handleClick}
      data-testid={`faq-item-${index}`}
      aria-hidden="true"
    >
      <div className={`${namespace}__number`}>
        <IconNumber number={number} />
      </div>
      <div className={`${namespace}__text`}>
        <h6 className={`${namespace}__title`}>{title}</h6>
        <div
          className={`${namespace}__content`}
          ref={contentRef}
          style={{
            maxHeight: isActive ? `${maxHeight}` : '0px',
          }}
        >
          <p className={`${namespace}__description`}>{description}</p>
        </div>
      </div>
      <div className={`${namespace}__arrow`}>
        <ArrowDownBig className={`${namespace}__arrow-icon`} />
      </div>
    </div>
  );
};

FAQAccordionItem.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default FAQAccordionItem;

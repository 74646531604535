import React from 'react';
import PropTypes from 'prop-types';

import TestimonialCard from '../../../../components/TestimonialCard';
import Carousel from '../../../../components/Carousel';

const CasesMobile = ({ cards, title }) => {
  const namespace = 'ui-ms-landing-cases';

  return (
    <section className={`${namespace}`}>
      <h2 className={`${namespace}__title`}>{title}</h2>
      <div className={`${namespace}__carousel`}>
        <Carousel colorMode="light">
          {cards.map(
            ({ id, brand, testimony, image, color, bgColor, rating }) => (
              <TestimonialCard
                key={id}
                testimony={testimony}
                image={image}
                brand={brand}
                color={color}
                bgColor={bgColor}
                rating={rating}
              />
            ),
          )}
        </Carousel>
      </div>
    </section>
  );
};

CasesMobile.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      testimony: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      brand: PropTypes.string,
      color: PropTypes.string.isRequired,
      bgColor: PropTypes.string.isRequired,
      rating: PropTypes.number.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default CasesMobile;

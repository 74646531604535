import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';

import Check from '../../assets/icons/Check';

const PriceTable = ({ initialRowCount, items, showAllRows }) => {
  const tableRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(null);

  const pxToRem = (px) => `${(px / 13).toFixed(2)}rem`;

  useEffect(() => {
    const newMaxHeight = showAllRows
      ? tableRef.current.offsetHeight
      : 59 * initialRowCount + 29;

    setMaxHeight(newMaxHeight);
  }, [showAllRows, initialRowCount]);

  const minHeight = pxToRem(59 * initialRowCount + 29);

  const iconComponents = {
    Check: (
      <Check
        color="white"
        className=" ui-ms-landing-price-table__item-value--icon"
      />
    ),
    Default: (
      <Check
        color="white"
        className=" ui-ms-landing-price-table__item-value--icon"
      />
    ),
  };

  return (
    <div
      className="ui-ms-landing-price-table-container"
      style={{
        maxHeight: `${pxToRem(maxHeight)}`,
        minHeight: `${minHeight}`,
        overflow: 'hidden',
      }}
    >
      <div className="ui-ms-landing-price-table" ref={tableRef}>
        {items.map(({ label, value, icon }, i) => {
          const show = showAllRows || i < initialRowCount;
          const iconKey = icon && icon in iconComponents ? icon : 'Default';
          const iconComponent = iconComponents[iconKey];

          return (
            <div className="ui-ms-landing-price-table__items" key={uuidv4()}>
              <div
                className="ui-ms-landing-price-table__item ui-ms-landing-price-table__item-title"
                data-testid="item-title"
              >
                <span
                  className={
                    show ? '' : 'ui-ms-landing-price-table__item--fade-out '
                  }
                >
                  {show && label}
                </span>
              </div>
              <div className="ui-ms-landing-price-table__item ui-ms-landing-price-table__item-value">
                <span
                  className={
                    show ? '' : 'ui-ms-landing-price-table__item--fade-out '
                  }
                >
                  {show && (!value && icon ? iconComponent : value)}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PriceTable.propTypes = {
  initialRowCount: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      icon: PropTypes.string,
    }),
  ).isRequired,
  showAllRows: PropTypes.bool.isRequired,
};

PriceTable.defaultProps = {
  items: [],
  initialRowCount: 8,
};

export default PriceTable;

import React from 'react';
import PropTypes from 'prop-types';

import FloatingCard from '../../../../components/FloatingCard';

const EcosystemMobile = ({ cards, title, subtitle }) => {
  const namespace = 'ui-ms-landing-ecosystem';

  return (
    <section className={`${namespace}`}>
      <div className={`${namespace}__texts`}>
        <h2 className={`${namespace}__title`}>{title}</h2>
        <h5 className={`${namespace}__subtitle`}>{subtitle}</h5>
      </div>
      <div className={`${namespace}__card-container`}>
        {cards.map(({ image, border, background, opacity, size, id }) => (
          <FloatingCard
            image={image}
            borderRadius={border}
            bgColor={background}
            isFaded={opacity}
            size={size}
            key={id}
          />
        ))}
      </div>
    </section>
  );
};

EcosystemMobile.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      background: PropTypes.string.isRequired,
      border: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      opacity: PropTypes.bool,
      size: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default EcosystemMobile;

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import { TabsContext } from './TabsContext';

const Tabs = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [fade, setFade] = useState(false);
  const [visibleContent, setVisibleContent] = useState(activeTab);
  const [initialized, setInitialized] = useState(false);
  const tabRefs = useRef([]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
    }

    setFade(true);

    const timeout = setTimeout(() => {
      setVisibleContent(activeTab);
      setFade(false);
    }, 300);

    return () => clearTimeout(timeout);
  }, [activeTab, initialized]);

  const handleTabClick = useCallback(
    (index) => {
      if (index !== activeTab) {
        setActiveTab(index);
      }
    },
    [activeTab],
  );

  const pillStyle = useMemo(() => {
    if (!initialized) {
      return {};
    }

    const activeTabRef = tabRefs.current[activeTab];

    if (activeTabRef) {
      return {
        left: `${activeTabRef.offsetLeft - 8}px`,
        width: `${activeTabRef.offsetWidth}px`,
      };
    }

    return {};
  }, [activeTab, initialized]);

  const namespace = 'ui-ms-landing-tabs';

  const contextValue = useMemo(
    () => ({
      activeTab,
      handleTabClick,
      tabRefs,
      pillStyle,
      fade,
      visibleContent,
    }),
    [activeTab, handleTabClick, pillStyle, fade, visibleContent],
  );

  return (
    <TabsContext.Provider value={contextValue}>
      <div className={namespace}>{children}</div>
    </TabsContext.Provider>
  );
};

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tabs;

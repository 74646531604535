const dataEcosystem = (i18n, siteId) => ({
  title: i18n.gettext('Haz crecer tus ventas'),
  /* i18n-next-line except: ["es-AR"] */
  subtitle: i18n.gettext('Con todo Mercado Libre a tu favor'),
  cardsDesktop: [
    {
      id: '1',
      image: `home/${siteId}/card-ecosystems_01.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
    {
      id: '2',
      image: `home/${siteId}/card-ecosystems_02.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
    {
      id: '3',
      image: `home/${siteId}/card-ecosystems_03.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
    {
      id: '4',
      image: `home/${siteId}/card-ecosystems_04.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
    {
      id: '5',
      image: `home/${siteId}/card-ecosystems_05.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
    {
      id: '6',
      image: `home/${siteId}/card-ecosystems_06.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
    {
      id: '7',
      image: `home/${siteId}/card-ecosystems_07.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
    {
      id: '8',
      image: `home/${siteId}/card-ecosystems_08.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
    {
      id: '9',
      image: `home/${siteId}/card-ecosystems_09.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
    {
      id: '10',
      image: `home/${siteId}/card-ecosystems_10.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
  ],
  cardsMobile: [
    {
      id: '1',
      image: `home/${siteId}/card-ecosystems.mobile_01.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
    {
      id: '2',
      image: `home/${siteId}/card-ecosystems.mobile_02.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'lg',
    },
    {
      id: '3',
      image: `home/${siteId}/card-ecosystems.mobile_03.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'sm',
    },
    {
      id: '4',
      image: `home/${siteId}/card-ecosystems.mobile_04.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'sm',
    },
    {
      id: '5',
      image: `home/${siteId}/card-ecosystems.mobile_05.png`,
      background: 'transparent',
      opacity: false,
      border: 'md',
      size: 'sm',
    },
  ],
});

export default dataEcosystem;

import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

const TypingUrl = ({ title, image = 'home/phone_animation.png' }) => {
  const namespace = 'ui-ms-typing-url-animation';

  return (
    <div className={`${namespace}`}>
      <div className={`${namespace}__cont-image`}>
        <Image
          src={image}
          className={`${namespace}__image ${namespace}__image-animation`}
          alt={title}
          tabIndex="-1"
        />
      </div>
      <div className={`${namespace}__cont-text ${namespace}__pill-animation`}>
        <p className={`${namespace}__text ${namespace}__typewriter-animation`}>
          {title}
        </p>
      </div>
    </div>
  );
};

TypingUrl.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
};

export default TypingUrl;

import commonTodaySold from './commonTodaySold';

const contentMarquee = (i18n) => [
  {
    id: 0,
    title: commonTodaySold(i18n),
  },
  {
    id: 1,
    /* i18n-next-line except: ["es-AR"] */
    title: i18n.gettext('En todos lados'),
  },
  {
    id: 2,
    /* i18n-next-line except: ["pt-BR", "es-AR"] */
    title: i18n.gettext('24/7'),
  },
  {
    id: 3,
    /* i18n-next-line except: ["es-AR"] */
    title: i18n.gettext('En un clic'),
  },
];

export default contentMarquee;

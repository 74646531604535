import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Slider from 'react-slick';
import classNames from 'classnames';

import IconButton from '../IconButton';
import ArrowLeft from '../../assets/icons/ArrowLeft';
import ArrowRight from '../../assets/icons/ArrowRight';

const Carousel = forwardRef(
  ({ children, colorMode, safeGapItems, ...props }, ref) => {
    const sliderClass = classNames(
      `ui-ms-landing-carousel__${colorMode}`,
      'ui-ms-landing-carousel',
      {
        'ui-ms-landing-carousel__safe--gap': safeGapItems,
      },
    );

    return (
      <Slider {...props} className={sliderClass} ref={ref}>
        {children}
      </Slider>
    );
  },
);

Carousel.displayName = 'Carousel';

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  colorMode: PropTypes.oneOf(['dark', 'light']),
  safeGapItems: PropTypes.bool,
  fade: PropTypes.bool,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  arrows: PropTypes.bool,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  appendDots: PropTypes.func,
  customPaging: PropTypes.func,
  responsive: PropTypes.arrayOf(
    PropTypes.shape({
      breakpoint: PropTypes.number,
      settings: PropTypes.shape({
        fade: PropTypes.bool,
        slidesToShow: PropTypes.number,
        arrows: PropTypes.bool,
      }),
    }),
  ),
  prevArrow: PropTypes.element,
  nextArrow: PropTypes.element,
};

Carousel.defaultProps = {
  dots: true,
  infinite: true,
  arrows: true,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  appendDots: (dots) => <ul>{dots}</ul>,
  customPaging: () => <div className="ui-ms-landing-carousel__dots--custom" />,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        fade: false,
        slidesToShow: 1.025,
        arrows: false,
      },
    },
  ],
  prevArrow: (
    <IconButton
      variant="primary"
      size="xlg"
      colorPalette="white-yellow"
      icon={<ArrowLeft />}
    />
  ),
  nextArrow: (
    <IconButton
      variant="primary"
      size="xlg"
      colorPalette="white-yellow"
      icon={<ArrowRight />}
    />
  ),
  safeGapItems: true,
  colorMode: 'dark',
};

export default Carousel;

import React from 'react';
import PropTypes from 'prop-types';

import { Script } from 'nordic/script';

const Gtm = ({ lowEnd, id }) => (
  /* Key for analytics */
  <div>
    {lowEnd ? (
      <iframe
        title="Google Tag Manager"
        src={`https://www.googletagmanager.com/ns.html?id=${id}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    ) : (
      <Script on="now" crossorigin>
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${id}');
      `}
      </Script>
    )}
  </div>
);

Gtm.propTypes = {
  lowEnd: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default Gtm;

const URL_TEXT_ANIMATIONS = {
  MLA: 'Biolibre.ar/mishop',
  MLB: 'Biolivre.com.br/meushop',
  MLC: 'Biolibre.cl/mishop',
  MCO: 'Biolibre.co/mishop',
  MLM: 'Biolibre.mx/mishop',
};

const contentTools = (i18n, siteId) => ({
  /* i18n-next-line except: ["es-AR"] */
  title: i18n.gettext(
    'Todas las herramientas de marketing desde el primer momento',
  ),
  description: i18n.gettext(
    'Encuentra a potenciales compradores y acelera tus ventas con todas las herramientas que tienes incorporadas al crear tu tienda.',
  ),
  cards: [
    {
      text: i18n.gettext('Convierte a tus seguidores en compradores'),
      animation: {
        name: 'typingUrl',
        settings: {
          title: URL_TEXT_ANIMATIONS[siteId],
        },
      },
    },
    {
      text: i18n.gettext(
        'Logra más visitas a tu tienda con soluciones de tráfico gratuito',
      ),
      animation: {
        name: 'pillFade',
        settings: {
          items: [
            {
              /* i18n-next-line except: ["es-AR"] */
              title: i18n.gettext('E-mail marketing'),
            },
            {
              /* i18n-next-line except: ["es-AR"] */
              title: i18n.gettext('Posicionamiento SEO'),
            },
            {
              /* i18n-next-line except: ["es-AR"] */
              title: i18n.gettext('Base de datos'),
            },
          ],
        },
      },
    },
    {
      text: i18n.gettext(
        'Muestra tus productos con una estrategia de publicidad online',
      ),
      animation: {
        name: 'imageFade',
        settings: {
          items: [
            {
              title: 'Instagram',
              image: 'home/toolcard_sm_01.png',
            },
            {
              title: 'Facebook',
              image: 'home/toolcard_sm_02.png',
            },
            {
              title: 'TikTok',
              image: 'home/toolcard_sm_03.png',
            },
            {
              title: 'Google',
              image: 'home/toolcard_sm_04.png',
            },
            {
              title: 'Mercado Ads',
              image: 'home/toolcard_sm_05.png',
            },
          ],
        },
      },
    },
  ],
});

export default contentTools;

import React from 'react';
import PropTypes from 'prop-types';

import HeroDesktop from './HeroDesktop';
import HeroMobile from './HeroMobile';

const Hero = ({ i18n, siteId, deviceType }) => {
  const heroByDevice = {
    mobile: HeroMobile,
    tablet: HeroDesktop,
    desktop: HeroDesktop,
  };

  const HeroComponent = heroByDevice[deviceType] || HeroDesktop;

  return <HeroComponent i18n={i18n} siteId={siteId} />;
};

Hero.propTypes = {
  siteId: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
};

export default Hero;

import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import MelidataTrackerService from '@mshops-web-core/melidata-tracker';

import Button from '../../../../components/Button';
import FloatingCard from '../../../../components/FloatingCard';
import contentHero from '../../../../wordings/hero';
import { MainBanner } from '../../../../constants/melidata-events/activate-events';

const HeroDesktop = ({ i18n, siteId }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [visibleImage, setVisibleImage] = useState(null);
  const throttleTimeout = useRef(null);
  const hoverAreaRef = useRef(null);

  const heroContent = contentHero(i18n, siteId);
  const { title, subtitle, copy, button, cardImages } = heroContent;

  const namespace = 'ui-ms-landing-hero';

  const CARD_WIDTH = 218;
  const CARD_HEIGHT = 283;

  useEffect(() => {
    let initialTimeout;

    if (hoverAreaRef.current) {
      const hoverAreaRect = hoverAreaRef.current.getBoundingClientRect();
      const centerX = hoverAreaRect.left + hoverAreaRect.width / 2;
      const centerY = hoverAreaRect.top + hoverAreaRect.height / 2;

      const cardX = centerX - CARD_WIDTH / 2;
      const cardY = centerY - CARD_HEIGHT / 1.5;

      setPosition({ x: cardX, y: cardY });
      initialTimeout = setTimeout(() => {
        setIsHovered(true);
        setVisibleImage(1);
      }, 500);
    }

    return () => clearTimeout(initialTimeout);
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (hoverAreaRef.current.contains(e.target)) {
      if (throttleTimeout.current === null) {
        throttleTimeout.current = setTimeout(() => {
          const hoverAreaRect = hoverAreaRef.current.getBoundingClientRect();

          let offsetX = e.clientX - hoverAreaRect.left - CARD_WIDTH / 2;
          let offsetY = e.clientY - hoverAreaRect.top - CARD_HEIGHT / 2;

          offsetX = Math.min(
            Math.max(offsetX, 0),
            hoverAreaRect.width - CARD_WIDTH,
          );

          offsetY = Math.min(
            Math.max(offsetY, 0),
            hoverAreaRect.height - CARD_HEIGHT,
          );

          setPosition({ x: offsetX, y: offsetY });

          throttleTimeout.current = null;
        }, 50);
      }
    } else {
      setIsHovered(false);
    }
  }, []);

  useEffect(
    () => () => {
      if (throttleTimeout.current !== null) {
        clearTimeout(throttleTimeout.current);
      }
    },
    [],
  );

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleColumnMouseEnter = (index) => {
    setVisibleImage(index);
  };

  const hoverClassName = classNames({
    [`${namespace}__hover-area--card`]: true,
    [`${namespace}__hover-area--card--visible`]: isHovered,
  });

  const hoverAnimation = {
    transform: `translate(${position.x}px, ${position.y}px) ${
      isHovered ? 'scale(1)' : 'scale(0)'
    }`,
  };

  const handleClickButtonSubmit = useCallback(() => {
    MelidataTrackerService.notify(MainBanner);
  }, []);

  return (
    <section className={`${namespace}`}>
      <div
        className={`${namespace}__hover-area`}
        ref={hoverAreaRef}
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={hoverClassName} style={hoverAnimation}>
          {cardImages.map((image) => {
            const floatinCardsClassName = classNames({
              [`${namespace}__hover-area-floating-card`]: true,
              [`${namespace}__hover-area-floating-card--visible`]:
                visibleImage === image.id,
            });

            return (
              <FloatingCard
                key={image.id}
                image={image.image}
                size="md"
                bgColor="transparent"
                className={floatinCardsClassName}
              />
            );
          })}
        </div>
        <div className={`${namespace}__cols`}>
          {cardImages.map((image) => (
            <div
              key={image.id}
              className={`${namespace}__cols--col`}
              onMouseEnter={() => handleColumnMouseEnter(image.id)}
            />
          ))}
        </div>
      </div>
      <div className={`${namespace}__container`}>
        <h1 className={`${namespace}__title`}>{title}</h1>
        <p className={`${namespace}__subtitle`}>{subtitle}</p>
        <p className={`${namespace}__copy`}>{copy}</p>
        <Button
          type="submit"
          text={button}
          colorPalette="yellow"
          hasIconRight
          className={`${namespace}__button--z`}
          onClick={handleClickButtonSubmit}
        />
      </div>
    </section>
  );
};

HeroDesktop.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default HeroDesktop;

import React from 'react';
import PropTypes from 'prop-types';

const ArrowRight = ({ size = '24', color = 'black', className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    className={className}
  >
    <path d="M23.8,11.2c-0.1-0.2-0.2-0.5-0.4-0.7l-10-10c-0.2-0.2-0.4-0.3-0.7-0.4C12.5,0.1,12.3,0,12,0c-0.5,0-1,0.2-1.4,0.6 c-0.2,0.2-0.3,0.4-0.4,0.7C10,1.5,10,1.7,10,2c0,0.5,0.2,1,0.6,1.4l6.6,6.6H2c-0.5,0-1,0.2-1.4,0.6C0.2,11,0,11.5,0,12 c0,0.5,0.2,1,0.6,1.4C1,13.8,1.5,14,2,14h15.2l-6.6,6.6c-0.2,0.2-0.3,0.4-0.4,0.7C10, 21.5,10,21.7,10,22c0,0.3,0.1,0.5,0.2,0.8 c0.1,0.2,0.3,0.5,0.4,0.7c0.2,0.2,0.4,0.3,0.7,0.4c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.5-0.3,0.7-0.4l10-10 c0.2-0.2,0.3-0.4,0.4-0.7C24,12.3,24,11.7,23.8,11.2z" />
  </svg>
);

ArrowRight.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default ArrowRight;

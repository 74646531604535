import React from 'react';
import PropTypes from 'prop-types';

const ArrowDownBig = ({ className, color }) => (
  <svg
    width="47"
    height="49"
    viewBox="0 0 47 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M22.5429 47.5958C23.1286 48.1816 24.0784 48.1816 24.6642 47.5958L34.2101 38.0499C34.7959 37.4641 34.7959 36.5143 34.2101 35.9286C33.6243 35.3428 32.6746 35.3428 32.0888 35.9286L23.6035 44.4138L15.1182 35.9286C14.5324 35.3428 13.5827 35.3428 12.9969 35.9286C12.4111 36.5143 12.4111 37.4641 12.9969 38.0499L22.5429 47.5958ZM22.1035 0.328125V46.5352H25.1035V0.328125H22.1035Z"
      fill={color}
    />
  </svg>
);

ArrowDownBig.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

ArrowDownBig.defaultProps = {
  color: 'black',
  className: 'ui-ms-landing-icon--arrow-down-big',
};

export default ArrowDownBig;

const blank = '';
/* i18n-next-line except: ["es-AR"] */
const introText = (i18n) => i18n.gettext('Haz clic en {0}', blank);
/* i18n-next-line except: ["pt-BR", "es-AR"] */
const buttonText = (i18n) => i18n.gettext('"Crear mi tienda".');
/* i18n-next-line except: ["pt-BR", "es-AR"] */
const buttonTextMLB = (i18n) => i18n.gettext('"Criar minha loja".');
/* i18n-next-line except: ["es-AR"] */
const outroText = (i18n) => i18n.gettext('{0} ¡Tu Shop ya está activo!', blank);

const defineConditions = (i18n) =>
  i18n.gettext(
    'Define las condiciones de venta como el precio del producto, la modalidad de envío y tipo de publicación.',
  );

const doneSale = (i18n) =>
  /* i18n-next-line except: ["es-AR"] */
  i18n.gettext('¡Listo! Ya estás vendiendo en un nuevo canal.');

const customYourShop = (i18n) =>
  i18n.gettext(
    'Personaliza tu tienda online con el logo y colores de tu marca.',
  );

const dataCreateYourShop = (i18n, siteId) => ({
  /* i18n-next-line except: ["es-AR"] */
  title: i18n.gettext('Cómo crear tu Mercado Shops gratis'),
  /* i18n-next-line except: ["es-AR"] */
  sellingTab: i18n.gettext('Vendo en Mercado Libre'),
  /* i18n-next-line except: ["es-AR"] */
  notSellingTab: i18n.gettext('No vendo en Mercado Libre'),
  sellingStep: [
    {
      id: 0,
      stepNumber: '1',
      title: '',
      titleWithButton: {
        introText: introText(i18n),
        buttonText: siteId === 'MLB' ? buttonTextMLB(i18n) : buttonText(i18n),
        buttonLabelEvent: 'meli_seller',
        outroText: outroText(i18n),
      },
      image: `home/${siteId}/step-vendo-1.png`,
    },
    {
      id: 1,
      stepNumber: '2',
      title: i18n.gettext(
        'Tu Mercado Shops se activa con tus publicaciones de Mercado Libre. En cualquier momento puedes elegir si vender en ambos canales o en el que prefieras.',
      ),
      image: `home/${siteId}/step-vendo-2.png`,
    },
    {
      id: 2,
      stepNumber: '3',
      title: customYourShop(i18n),
      image: `home/${siteId}/step-vendo-3.png`,
    },
    {
      id: 3,
      stepNumber: '4',
      title: doneSale(i18n),
      image: `home/${siteId}/step-vendo-4.png`,
    },
  ],
  notSellingStep: [
    {
      id: 0,
      stepNumber: '1',
      title: '',
      titleWithButton: {
        introText: introText(i18n),
        buttonText: siteId === 'MLB' ? buttonTextMLB(i18n) : buttonText(i18n),
        buttonLabelEvent: 'not_meli_seller',
        outroText: outroText(i18n),
      },
      image: `home/${siteId}/step-no-vendo-1.png`,
    },
    {
      id: 1,
      stepNumber: '2',
      title: i18n.gettext(
        'Elige qué productos vas a publicar y dónde. Puedes publicarlos en tu tienda y venderlos también en Mercado Libre.',
      ),
      image: `home/${siteId}/step-no-vendo-2.png`,
    },
    {
      id: 2,
      stepNumber: '3',
      title: defineConditions(i18n),
      image: `home/${siteId}/step-no-vendo-3.png`,
    },
    {
      id: 3,
      stepNumber: '4',
      title: customYourShop(i18n),
      image: `home/${siteId}/step-no-vendo-4.png`,
    },
    {
      id: 4,
      stepNumber: '5',
      title: doneSale(i18n),
      image: `home/${siteId}/step-no-vendo-5.png`,
    },
  ],
});

export default dataCreateYourShop;

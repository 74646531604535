const ImageBrand = {
  MLA: 'home/MLA/card_cta_tienda',
  MLB: 'home/MLB/card_cta_tienda',
  MLC: 'home/MLC/card_cta_tienda',
  MCO: 'home/MCO/card_cta_tienda',
  MLM: 'home/MLM/card_cta_tienda',
};

const ImageTrafic = {
  MLA: 'home/MLA/card_cta_trafico',
  MLB: 'home/MLB/card_cta_trafico',
  MLC: 'home/card_cta_trafico',
  MCO: 'home/card_cta_trafico',
  MLM: 'home/card_cta_trafico',
};

const ImageDelivery = {
  MLA: 'home/MLA/card_cta_envios',
  MLB: 'home/MLB/card_cta_envios',
  MLC: 'home/card_cta_envios',
  MCO: 'home/card_cta_envios',
  MLM: 'home/card_cta_envios',
};

const startNow = (i18n) => i18n.gettext('Empieza hoy');

const dataSales = (i18n, siteId) => [
  {
    id: 0,
    title: i18n.gettext(
      'Vende en todos los lugares donde están tus compradores',
    ),
    image: ImageBrand[siteId],
    text: i18n.gettext(
      'Más canales de compra ofreces, más oportunidades de vender tienes. Vender tus productos desde tu Mercado Shops, en Mercado Libre, en tu local físico o desde tus redes sociales en simultáneo, es simple.',
    ),
    cta: startNow(i18n),
  },
  {
    id: 1,
    title: i18n.gettext(
      'Somos expertos en tráfico para que tú no tengas que serlo',
    ),
    image: ImageTrafic[siteId],
    text: i18n.gettext(
      '¡No te limites! Muéstrale al mundo tus productos, amplía tu exposición y aumenta tus ventas, con herramientas gratuitas y pagas incorporadas en tu tienda desde el primer momento.',
    ),
    cta: startNow(i18n),
  },
  {
    id: 2,
    title: i18n.gettext('Brinda la mejor experiencia de compra'),
    image: 'home/card_cta_mp',
    text: i18n.gettext(
      'Accede a todos los recursos de Mercado Libre sin costos adicionales, mejora la conversión de tu checkout gracias a los millones de usuarios ya logueados en Mercado Pago y cuenta con transacciones protegidas.',
    ),
    cta: startNow(i18n),
  },
  {
    id: 3,
    /* i18n-next-line except: ["es-AR"] */
    title: i18n.gettext('Flexibilidad para definir tu margen'),
    image: 'home/card_cta_precios',
    text: i18n.gettext(
      'Define distintos precios, tipo de publicación y modalidad de envío por producto entre Mercado Libre y tu tienda virtual y cuenta con las tasas más competitivas del mercado.',
    ),
    cta: startNow(i18n),
  },
  {
    id: 3,
    title: i18n.gettext('Llega a todo el país en hasta 24 horas'),
    image: ImageDelivery[siteId],
    text: i18n.gettext(
      'Relájate con la tranquilidad de los Envíos Full de Mercado Libre y sigue generando ventas mientras miles de paquetes se preparan y despachan a tus clientes. Además, todos tus envíos están protegidos.',
    ),
    cta: startNow(i18n),
  },
];

export default dataSales;

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

const Card = ({
  bgColor,
  borderRadius,
  bgImage,
  width,
  height,
  className,
  children,
}) => {
  const cardClassName = classNames(
    { [`ui-ms-landing-card__primary-${bgColor}`]: true },
    { [`ui-ms-landing-card__border-radius-${borderRadius}`]: true },
    { 'ui-ms-landing-card__image': bgImage },
    'ui-ms-landing-card',
    className,
  );

  const cardStyle = {
    backgroundImage: bgImage && `url(${bgImage})`,
    width,
    height,
  };

  return (
    <div className={cardClassName} style={cardStyle}>
      {children}
    </div>
  );
};

Card.propTypes = {
  bgColor: PropTypes.oneOf([
    'yellow',
    'pink',
    'black',
    'blue',
    'white',
    'transparent',
  ]),
  borderRadius: PropTypes.oneOf(['sm', 'md', 'lg']),
  bgImage: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Card.defaultProps = {
  bgColor: 'white',
  borderRadius: 'md',
  bgImage: null,
};

export default Card;

const buildDeeplink = ({ url, params }) => {
  const baseDeeplink = 'meli://webview/?webkit-engine=2&url=';

  const deeplinkQueryParams = Object.keys(params).map(
    (key) => `${key}=${params[key]}`,
  );

  return `${baseDeeplink + encodeURIComponent(url)}&${deeplinkQueryParams.join(
    '&',
  )}`;
};

const executeNative = async (method, args) => {
  await window.MobileWebKit.executeNative({ method, args });
};

const openInternalDeeplink = async (deeplink) => {
  if (window?.MobileWebKit) {
    if (window.MobileWebKit.engine === window.MobileWebKit.Engines.V200) {
      await window.MobileWebKit.navigation.push(deeplink);
    } else {
      await executeNative('open_internal_deeplink', {
        deeplink,
        replace: true,
      });
    }
  }
};

export { buildDeeplink, openInternalDeeplink };

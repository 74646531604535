const COMMON_VALUES = {
  action: 'click_activate',
  category: 'landing',
};

const getCommonDataEvents = (label) => ({
  ...COMMON_VALUES,
  label,
});

export { COMMON_VALUES, getCommonDataEvents };

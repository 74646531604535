import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

import Card from '../Card';
import { PillFade, TypingUrl, ImageFade } from './animations';

const animationComponents = {
  pillFade: PillFade,
  typingUrl: TypingUrl,
  imageFade: ImageFade,
};

const InfoCard = ({ image = '', animation = null, title, text = '' }) => {
  const namespace = 'ui-ms-landing-infocard';

  const AnimationComponent = animationComponents[animation?.name] ?? null;

  let renderedContent = null;

  if (AnimationComponent) {
    renderedContent = <AnimationComponent {...animation.settings} />;
  } else if (image) {
    renderedContent = (
      <Image src={image} alt={title} className={`${namespace}__image`} />
    );
  }

  return (
    <Card bgColor="white" className={namespace} borderRadius="md">
      {renderedContent && (
        <div className={`${namespace}__image-container`}>{renderedContent}</div>
      )}
      <div className={`${namespace}__text-container`}>
        <h6 className={`${namespace}__title`}>{title}</h6>
        {text && <p className={`${namespace}__text`}>{text}</p>}
      </div>
    </Card>
  );
};

InfoCard.propTypes = {
  image: PropTypes.string,
  animation: PropTypes.shape({
    name: PropTypes.oneOf(['typingUrl', 'pillFade', 'imageFade']).isRequired,
    settings: PropTypes.shape({}).isRequired,
  }),
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default InfoCard;

const dataShopWithoutLimits = (i18n) => ({
  /* i18n-next-line except: ["es-AR"] */
  title: i18n.gettext('Tu negocio no tiene límites, tu Shop tampoco'),
  /* i18n-next-line except: ["es-AR"] */
  description: i18n.gettext(
    'Suma hoy un nuevo canal de ventas y vende en todos los lugares donde están tus compradores.',
  ),
  button: i18n.gettext('Empieza hoy'),
  image: 'home/card_mshops',
});

export default dataShopWithoutLimits;

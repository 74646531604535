import React from 'react';
import PropTypes from 'prop-types';

import { v4 as uuidv4 } from 'uuid';
import { Image } from 'nordic/image';

const ImageFade = ({ items }) => {
  const namespace = 'ui-ms-image-fade-animation';

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div className={namespace}>
      <div className={`${namespace}__circle`}>
        <div
          className={`${namespace}__logos-cont ${namespace}__logos-animation`}
        >
          {items.map(({ image, title }) => (
            <div key={uuidv4()} className={`${namespace}__logos`}>
              <Image src={image} alt={title} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ImageFade.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ImageFade;

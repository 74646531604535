import React from 'react';
import PropTypes from 'prop-types';

import EcosystemDesktop from './EcosystemDesktop';
import EcosystemMobile from './EcosystemMobile';
import dataEcosystem from '../../../../wordings/ecoSystem';

const Ecosystem = ({ deviceType, i18n, siteId }) => {
  const data = dataEcosystem(i18n, siteId);
  const { title, subtitle, cardsDesktop, cardsMobile } = data;

  const Component =
    deviceType === 'desktop' ? EcosystemDesktop : EcosystemMobile;

  return (
    <Component
      title={title}
      subtitle={subtitle}
      cards={deviceType === 'desktop' ? cardsDesktop : cardsMobile}
    />
  );
};

Ecosystem.propTypes = {
  deviceType: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default Ecosystem;

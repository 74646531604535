import React from 'react';
import PropTypes from 'prop-types';

const Check = ({ size, color, className }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    className={className}
  >
    <path d="M12,24C5.4,24,0,18.6,0,12S5.4,0,12,0c6.6,0,12,5.4,12,12S18.6,24,12,24z M12,1.2C6.1,1.2,1.2,6.1,1.2,12 c0,5.9,4.8,10.8,10.8,10.8S22.8,17.9,22.8,12C22.8,6.1,17.9,1.2,12,1.2z" />
    <path d="M10,14.4l-2.2-2.7c-0.2-0.3-0.6-0.3-0.9-0.1c-0.3,0.2-0.3,0.6-0.1,0.9l2.2,2.7c0.2,0.3,0.6,0.4,0.9,0.5c0,0,0,0,0.1,0 c0.3,0,0.7-0.1,0.9-0.4l6.1-6c0.2-0.2,0.2-0.6,0-0.9c-0.2-0.2-0.6-0.2-0.9,0L10,14.4C10.1,14.5,10.1,14.5,10,14.4z" />
  </svg>
);

Check.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

Check.defaultProps = {
  color: 'black',
  className: 'ui-ms-icon-check',
  size: '24',
};

export default Check;
